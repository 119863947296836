import React from 'react'
import FAQ from '../../../components/FAQ'
import PageSeo from '../../../components/SEO'
import FaqComponent from "../../../components/Common/Faq/FaqComponent";
import SullenFaqs from "../../../components/FAQ/SullenFaqs";

function index() {
    return (
        <>
            <PageSeo pageTitle="Sullen FAQs" />
            <div className="FAQCol">
              <div>
                <nav
                    className="navbar navbarContainer nav2"
                    role="navigation"
                    aria-label="main-navigation"
                >
                  <div className="container is-fluid nav-container">
                    <div className="navbar-brand">
                      <a
                          href="/"
                          className="logo-link"
                      >
                        <img
                            src={"https://influencex-profile-pictures.nyc3.digitaloceanspaces.com/brand_logo/75/logo.png"}
                            alt="navLogo"
                            className="image navbarImage"
                            loading="lazy"
                            style={{maxHeight: 100, maxWidth: 100}}
                            title="navLogo"
                        />
                      </a>
                    </div>
                  </div>
                </nav>
              </div>
              <SullenFaqs />
            </div>
        </>
    )
}

export default index
