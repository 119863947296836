import React, {useEffect, useState} from "react";
import GetFaqItemsSite from "../../Common/Faq/GetFaqItems";
import {Spin} from "antd";
import FAQComponent from "../FAQComponent";

const SullenFaqs = () => {
    const [items, setItems] = useState([])
    const [groups, setGroups] = useState([])
    const [init, setInit] = useState(true)

    useEffect(()=>{
      GetFaqItemsSite({siteAddress: "faq_sullen", grouped: false})
          .then(r => {
            const a = []
            r.data.faq_items.map((b)=>(
                a.push({title: b.title, content: b.answer})
            ))
            setItems({rows: a})
            setGroups(r.data.faq_groups)
            setInit(false)
          })
    }, [])

    if(init) {
      return <Spin />
    }

    return(
        <div className="columns">
          <div className="column is-12">
            <FAQComponent
              title={"Sullen Ambassador Program FAQs"}
              data={items}
              mt={true}
            />
          </div>
        </div>
    )
}

export default SullenFaqs